<template>
  <div class="template field-report-template">
    <field-report-template-menu></field-report-template-menu>
    <div ref="header"></div>
    <div class="content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import FieldReportTemplateMenu from '@/components/modules/familyWebsite/templates/field_report/FieldReportTemplateMenu';

export default {
  mounted() {
    const mcrHeader = document.getElementsByClassName('mcr-header');
    if (mcrHeader.length) {
      // hack to move header out of content, so it is full width of page
      this.$nextTick(() => {
        this.$refs.header.appendChild(mcrHeader[0]);
      });
    }
  },
  components: {FieldReportTemplateMenu},
};
</script>

<style lang="scss" scoped>
@import '../../styles/variables-field';
@import '../../styles/base-styles-field';

.field-report-template .content {
  @import '../../styles/glossary';
  @import '../../styles/variables-field';
  @import '../../styles/ancestor';
  @import '../../styles/inline-commentary';
  @import '../../styles/feature';
  @import '../../styles/feature-sidebar';
  @import '../../styles/facts';
  @import '../../styles/siblings';
  @import '../../styles/additional-services-footer';
}
</style>
